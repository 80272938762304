import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { ButtonType } from '../common/typings/enums'
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import HeroSection from '../components/HeroSection'
import ContactSection from '../components/ContactSection'
import FormSection from '../components/FormSection'
import Footer from '../components/Footer'
import FloatingButton from '../components/FloatingButton'
import { IsMobileContext, LanguageContext } from '../common/Context'
import { useSeoData } from '../components/SeoComponent/model'
import { useLinksGridData, LinksGrid } from '../components/LinksGrid/model'
import { useNavbarData, Navbar as NavbarClass } from '../components/Navbar/model'
import { useHeroSectionData, HeroSection as HeroSectionClass } from '../components/HeroSection/model'
import { useContactSectionData, ContactSection as ContactSectionClass, KenticoContactSection } from '../components/ContactSection/model'
import { useFormtSectionData, FormSection as FormSectionClass } from '../components/FormSection/model'
import { AnimationService } from '../common/AnimationService'
import { useGeolocation } from '../hooks/useGeolocation'
import './contact.scss'
import withSSR from '../hoc/withSSR'

const HireUsPage: React.FC<PageProps> = ({ location: { pathname } }) => {
  const { language: initialLanguage } = React.useContext(LanguageContext)
  const [language, setLanguage] = React.useState(initialLanguage)

  /*  For data collecting hooks below (e.g. useLinksGridData), 
  You need to know page codename from kentico and optionally section codename.
  Data service is searching query for this elements and collects the right data to components  */
  const pageCodename = 'hire_us_page'
  const seoData = useSeoData(language, pageCodename, pathname)
  const pageData = {
    linksGridData: useLinksGridData(language, pageCodename),
    navbarData: useNavbarData(language, pageCodename, pathname),
    heroSectionData: useHeroSectionData(language, pageCodename),
    contactSectionData: useContactSectionData(language, pageCodename),
    formSectionData: useFormtSectionData(language, pageCodename)
  }
  console.log('NODE_ENV: ' + process.env.NODE_ENV)
  console.log('GATSBY_STAGING: ' + process.env.GATSBY_STAGING)

  return (
    <Layout seoData={seoData} language={language} setLanguage={setLanguage}>
      <HireUsPageTemplateWithSSR pageData={pageData} />
    </Layout>
  )
}

interface HireUsPageProps {
  isMobile: boolean
  pageData: {
    linksGridData: LinksGrid
    navbarData: NavbarClass
    heroSectionData: HeroSectionClass
    contactSectionData: ContactSectionClass[]
    formSectionData: FormSectionClass
  }
}

const HireUsPageTemplate: React.FC<HireUsPageProps> = ({
  isMobile,
  pageData: {
    linksGridData,
    navbarData,
    heroSectionData,
    contactSectionData,
    formSectionData
  }
}) => {
  const emptyContact = new ContactSectionClass({} as KenticoContactSection)
  const [contactData, setContactData] = React.useState<ContactSectionClass>(emptyContact)
  const contactRefs = React.useRef<React.ElementRef<typeof ContactSection>>(null)
  const formRefs = React.useRef<React.ElementRef<typeof FormSection>>(null)
  React.useEffect(() => {
    if (contactRefs.current && formRefs.current) {
      if (!AnimationService.isInView(contactRefs.current.header)) {
        AnimationService.slideSequenceUp([contactRefs.current.header, contactRefs.current.image])
      }
      if (!AnimationService.isInView(formRefs.current.header)) {
        AnimationService.slideSequenceUp([formRefs.current.header, formRefs.current.body])
      }
    }
  }, [])

  useGeolocation((countryCode: string | null) => {
    const defaultContact = contactSectionData.find(contact => contact.regionCodes.includes('general'))
    if (countryCode) {
      const regionalContact = contactSectionData.find(contact => contact.regionCodes.includes(countryCode))
      setContactData(regionalContact || defaultContact as ContactSectionClass)
    } else {
      setContactData(defaultContact as ContactSectionClass)
    }
  })

  return (
    <IsMobileContext.Provider value={{ isMobile }}>
      <header>
        <Navbar {...linksGridData} {...navbarData} buttonType={ButtonType.SECONDARY} />
      </header>
      <main className="HireUsPage">
        <HeroSection
          {...heroSectionData}
          headingClass="FontXXL"
          headingColumns={{ desktop: 9, mobile: 12 }} descriptionColumns={{ desktop: 6, mobile: 12 }}
        />
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-m mx-m items-start">
          <ContactSection ref={contactRefs} {...contactData} />
          <FormSection ref={formRefs} {...formSectionData} />
        </div>
      </main>
      <footer>
        <Footer  {...linksGridData} />
      </footer>
      <FloatingButton />
    </IsMobileContext.Provider>
  )
}
const HireUsPageTemplateWithSSR = withSSR<HireUsPageProps>(HireUsPageTemplate)

export default HireUsPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common","hire-us"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`