import * as React from 'react'
import { useMobile } from '../hooks/useMobile'

const URL = 'https://www.cloudflare.com/cdn-cgi/trace'
let countryCode: string | null = null

export const useGeolocation = (callback: (countryCode: string | null) => void) => {
  const { isMobile } = useMobile()

  React.useEffect(() => {
    const fetchGeolocationData = async () => {
      const response = await window.fetch(URL)
      if (!response.ok) {
        throw `http error, code: ${response.status}`
      }
      const data = await response.text()
      if (!data) {
        throw 'no data received'
      }
      const locationData = data.trim().split('\n').find(str => str.startsWith('loc='))?.slice(4)
      const countryCode = locationData?.toLowerCase() || null
      return countryCode
    }

    const getCountryCode = async () => {
      if (countryCode) {
        callback(countryCode)
      } else {
        try {
          countryCode = await fetchGeolocationData()  
          callback(countryCode)
        } catch (error) {
          console.log(`Geolocation error: ${error}`)
          callback(null)
        }
      }
    }

    if (typeof window !== undefined && typeof callback === 'function' && typeof isMobile !== 'undefined') {
      getCountryCode()
    }

  }, [callback, isMobile])
}